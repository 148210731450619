<template>
    <div class="pb-2">
        <!-- signature structure  -->

                <b-form-group
                  label="Signature"
                  class="required" 
                  style="font-size:20px;color:white;text-align:center"
                />

        <center>
            <!--<b-media class=""--> <div @click="openSignature('supervisor')" v-if="crewSign.image == null">                                      
                <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" rounded style="height: 200px; width: auto"/>
                </div>

                <div class="d-flex flex-wrap">
                    <input ref="supervisorSInput" type="file" class="d-none" @input="crewUpload" >
                </div>

            <!--</b-media>-->
            
            <img :src="crewSign.image" v-if="crewSign.image != null" class="rounded" height="180" width="200px" style="background: #ffffff;">
        </center>
        
        <center>
            <b-button variant="danger" class="mt-1 ml-2" v-if="crewSign.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions"> 
                <feather-icon icon="XIcon" />
            </b-button>
        </center>

        <!-- signature Model -->
        <b-modal centered hide-footer
            id="signatureModelCrew"
            ref="signatureModelCrew"
            title="Signature"
            no-close-on-backdrop
            @hide="hideSigantureCrew"
            @show="hideSigantureCrew"
        >
            <div v-if="show_default == true">
                <b-row>
                    <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
                        <center>
                            <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
                        </center>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col md="6">
                        <b-button variant="secondary" class="mt-0 w-100" @click="show_default = false">
                            <span class="text-nowrap">Add New Signature</span>
                        </b-button>
                    </b-col>

                    <b-col md="6">
                        <b-button variant="warning" class="mt-0 float-right w-100" @click="saveDefault()">
                            <span class="text-nowrap">Submit</span>
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <div v-else>
                <b-row>
                    <b-col md="12">
                        <VueSignaturePad
                            id="signature"
                            width="100%"
                            height="300px"
                            ref="signaturePad"
                            :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3 }"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-2">

                    <b-col md="3">
                        <b-button variant="primary" class="mt-0 w-100" @click="uploadSign()">
                            <span class="text-nowrap">Upload</span>
                        </b-button>
                    </b-col>

                <b-col md="3">

                    <b-button
                        variant="secondary"
                        class="mt-0 w-100"
                        v-if="having_default == true"
                        @click="show_default = true"
                    >
                        <span class="text-nowrap">Default</span>
                    </b-button>
                </b-col>

                    <b-col md="3">
                        <b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
                            <span class="text-nowrap">Clear</span>
                        </b-button>
                    </b-col>

                    <b-col md="3">
                        <b-button variant="warning" class="mt-0 w-100 float-right" @click="saveSignature">
                            <span class="text-nowrap">Submit</span>
                        </b-button>
                    </b-col>
                    
                </b-row>
            </div>

        </b-modal>
    </div>
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import Bus from "../../../event-bus";
import { GET_API, POST_API } from "../../../store/actions.type";
var moment = require('moment-timezone');

export default{
    name: 'CrewSignature',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, BFormFile
    },
    props: ['tindex','index','signature'],
    data(){
        return{
            // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                crewSign : this.signature,
                openedSignModelCrew:null,

                // default signature vars 
                show_default:false,
                having_default:false, 
                tempSign : {
                    image:null,
                    name:'',
                },
                disabledOptions:false,
        }
    },
    methods: {
        // signature functions
        hideSigantureCrew(){
            this.openedSignModelCrew = null;
        },

        openSignature(type){
            if (this.openedSignModelCrew == null) {

                this.show_default = this.having_default == true ? true : false;

                this.$refs['signatureModelCrew'].show();
                this.openedSignModelCrew = type;
            }
        },

        uploadSign(){                
            if (this.openedSignModelCrew == 'supervisor') {
                this.$refs['supervisorSInput'].click();
            }
            if (this.openedSignModelCrew == 'client') {
                this.$refs['clientSInput'].click();
            }
            this.$refs['signatureModelCrew'].hide();
        },

        clearSignature(){
            this.$refs.signaturePad.clearSignature();
        },

        saveSignature(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (isEmpty) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Signature is required',
                    showConfirmButton: false,
                    timer: 1500
                })
            }else{
                if (this.openedSignModelCrew == 'supervisor') {
                    this.crewSign.image = data;
                    this.crewSign.name = 'signature.png';
                    this.crewSign.default = 'no';
                } else {
                    this.clientSign.image = data;
                    this.clientSign.name = 'signature.png';
                }
                this.$refs['signatureModelCrew'].hide();

                // send image data to parent
                Bus.$emit('update-image', {tindex:this.tindex, index: this.index, data: this.crewSign});
            }
        },

        crewUpload(event){
            var input = event.target;
            var files = event.target.files

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];

                if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please upload .jpg, .jpeg, .jpg images only',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.$refs.supervisorSInput.value=null;

                } else if (image.size > 2097152) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.supervisorSInput.value=null;
                } else {
                    reader.onload = (e) => {
                        this.crewSign.image = e.target.result;
                        this.crewSign.name = image.name;
                        this.crewSign.default = 'no';
                    }
                    // send image data to parent
                    var obj = {tindex:this.tindex, index: this.index, data: this.crewSign}

                    Bus.$emit('update-image', obj);
                }
            }
        },
            
        removeSign(sign){
            this.crewSign.image = null;
            this.crewSign.name = '';
            this.crewSign.default = 'no';
            Bus.$emit('remove-image', this.index,this.tindex);
        },

        getDefaultSignature(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id:this.$store.getters.currentUser._id
                },
                api: '/api/get-default-signature'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    window.scrollTo(0,0);
                } else {
                    var data  = this.$store.getters.getResults.data;
                    
                    if (data == null || data.default_signature == null) {
                        this.having_default = false;
                    }else{
                        this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                        }

                        this.having_default = true;
                    }
                }
            });
        },

        saveDefault(){
            this.crewSign.image   = this.tempSign.image;
            this.crewSign.name    = 'signature.png';
            this.crewSign.default = 'yes';

            // send image data to parent
            var obj = {tindex:this.tindex, index: this.index, data: this.crewSign}

            Bus.$emit('update-image', obj);

            this.$refs['signatureModelCrew'].hide();
        },
    },
    mounted(){
        this.getDefaultSignature();

        Bus.$on('reset-signature', () => {
            this.crewSign.image = null;
            this.crewSign.name = '';
            this.crewSign.default = 'no';
        })
        if(this.$route.params.id){
      		this.disabledOptions = true;
      		//this.getFormDetails();
    	}
    }
}
</script>
